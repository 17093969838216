<template>
  <b-nav-item-dropdown
    :lazy="true"
    class="dropdown-notification mr-25"
    menu-class="dropdown-menu-media"
    left
    @show="buscarObservacoes"
  >
    <template #button-content>
      <feather-icon
        :badge="observacoes.total"
        badge-classes="bg-success"
        class="text-body"
        icon="MessageSquareIcon"
        size="21"
      />
    </template>

    <!-- Header -->
    <li class="dropdown-menu-header">
      <div class="dropdown-header d-flex px-1 py-1">
        <h4 class="notification-title mb-0 mr-auto">
          Observações
        </h4>
        <b-badge
          pill
          variant="light-primary"
        >
          {{ quantidadeNaoLidas }} observações <!-- não lidas -->
        </b-badge>
      </div>
    </li>

    <!-- Notifications -->
    <vue-perfect-scrollbar
      :settings="perfectScrollbarSettings"
      class="scrollable-container media-list scroll-area"
      tagname="li"
    >
      <!-- Account Notification -->
      <b-row
        v-for="observacao in observacoes.itensObservacao"
        :key="observacao.id"
        class="w-100 border-bottom-success"
      >
        <b-col>
          <span class="ml-1 font-weight-bolder">
            {{ observacao.titulo }}
          </span>
          <span class="float-right">{{ observacao.valor }}</span>
        </b-col>
      </b-row>
    </vue-perfect-scrollbar>
  </b-nav-item-dropdown>
</template>

<script>
/* eslint-disable no-param-reassign */
import { ref, computed } from '@vue/composition-api'
import {
  BNavItemDropdown, BBadge,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import Ripple from 'vue-ripple-directive'
import EventBus from '@app/shared/mixins/event-bus/eventBus'
import api from './services/api'

export default {
  components: {
    BNavItemDropdown,
    BBadge,
    VuePerfectScrollbar,
  },
  directives: {
    Ripple,
  },
  setup() {
    const observacoes = ref([])

    const buscarObservacoes = async () => {
      observacoes.value = (await api.getAll()).data
    }

    buscarObservacoes()

    EventBus.$on('atualizaObservacoes', () => buscarObservacoes())

    const perfectScrollbarSettings = {
      wheelPropagation: false,
    }

    const quantidadeNaoLidas = computed(() => observacoes.value.total)

    return {
      quantidadeNaoLidas,
      observacoes,
      buscarObservacoes,
      perfectScrollbarSettings,
    }
  },
}
</script>

<style>

</style>
