import axios from '@axios'

const PREFIX = 'observacoes'

export default {

  getAll() {
    return axios.get(`${PREFIX}`)
  },
}
